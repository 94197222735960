.partnershipBar {
    width: 100%;
    padding-bottom: 0.5%;
}

.horizontalGroup > div {
    display:flex;
    flex-direction: row;
}

.horizontalGroup kat-radiobutton  {
    margin-right: 20px;
}

.emailButton {
    align-self: center;
    margin-bottom: 0.65%;
}

.partnershipButton {
    align-self: center;
}

.inputRow {
    width: 100%;
    margin-bottom: 0.5%;
}

.jsonBox {
    width: 100%;
}

.labelWithTooltip {
    padding-bottom: 0.3rem;
}

.codeArea {
    --background: #f5f5f5;
    --font-family: ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace;
    width: 100%;
    --min-height: 115px;
}

.generatedEmailHeader {
    margin-top: 1.5% !important;
}

.generatedEmailText {
    font-size: 1.5em !important;
    word-break: break-all;
}