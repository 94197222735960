.keyMasterBar {
    width: 100%;
    padding-bottom: 0.5%;
}

.firstRow {
    margin-top: 5px;
}

.usePrefixCheckBox {
    align-self: center;
}

.horizontalGroup > div {
    margin-top: -8px;
    display: flex;
    flex-direction: row;
}

.horizontalGroup kat-radiobutton  {
    margin-right: 20px;
}

.convertRow {
    align-items: end;
}

.dataTypeRow {
    margin-top: 0.5%;
}

.convertButton {
    margin-top: 0.5%;
}