.searchBar {
    width: 100%;
    padding-bottom: 0.5%;
}

.searchRow {
    min-height: 100px;
}

.horizontalGroup {
    max-width: 275px !important;
}

.horizontalGroup > div {
    display:flex;
    flex-direction: row;
}

.horizontalGroup kat-radiobutton  {
    margin-right: 20px;
}

.searchButton {
    align-self: center;
}