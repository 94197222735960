.loadingSpinner {
    align-self: center;
}

.moveHistoryButtons {
    align-self: center;
    margin-top: 1%;
}

.loadHistoryButton {
    align-self: center;
    margin-top: 1%;
}

.diffBoxTabContent pre {
    background-color: transparent !important;
    color: #212529 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}