.resultAndToolsBox {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 0.5fr;
    padding-bottom: 0.5%;
}

.toolsColumn {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}