.redriveBar {
    width: 100%;
    padding-bottom: 0.5%;
}

.firstRedriveRow {
    min-height: 85px !important;
}

@media (min-width: 800px) and (max-width: 1200px) {
    .secondRedriveRow {
        margin-top: 1%;
    }
}

.horizontalGroup {
    max-width: 275px !important;
}

@media (max-width: 800px) {
    .horizontalGroup {
        margin-top: 3%;
    }

    .horizontalGroup > div {
        margin-top: -3%;
    }

    .flagCheckboxes {
        margin-top: 3%;
    }
}

.horizontalGroup > div {
    display:flex;
    flex-direction: row;
}

.horizontalGroup kat-radiobutton  {
    margin-right: 20px;
}

.redriveButton {
    align-self: center;
}

.redriveDate {
    --width: inherit;
    width: inherit;
}

.utcMessage {
    margin-top: 1% !important;
}

.failureTypeMessage {
    margin-top: 1.25%;
    margin-bottom: 0.75%;
}