.parentConsoleLink {
    margin-top: 2.5%;
}

.accordionMargin {
    margin-bottom: 1rem;
}

@media (max-width: 1064px) {
    .consoleLink {
        margin-bottom: 1.5%;
    }
}

.redriveError {
    word-break: break-word;
}

@media (max-width: 1064px) {
    .bottomSpace {
        margin-bottom: 2%;
    }
}

@media (min-width: 1064px) {
    .bottomSpace {
        margin-bottom: 1%;
    }
}

.recoverBottomSpace {
    margin-bottom: -2%;
}

@media (min-width: 800px) {
    .timestampWidth {
        min-width: 230px;
    }
}

.messageStyle {
    color: #002f36 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    background-color: #e3eced !important;
    border-radius: 0 !important;
    word-break: break-word;
}

.verticalAlignPolling {
    vertical-align: middle;
}