.navBar {
    width: 100%;
    padding-bottom: 0.5%;
}

.navBar kat-link {
    --color: #FFF;
    --color-hover: #ebf1f1;
    --color-active: #ebf1f1;
    --color-link: #ebf1f1;
    --color-visited: #ebf1f1;
    --focus-ring-color: #ebf1f1;
}

.navBox {
    align-items: center;
}
