.modalText {
    word-break: break-all;
}

.redriveResultBox {
    margin-top: 2.5%;
}

.consoleLink {
    margin-top: 2.5%;
}