.headerDate {
    position: relative;
    top: -1px;
}

.hiddenStopDate {
    color: white;
}

.labelRow {
    align-items: center;
}

@media (min-width: 840px) {
    .redriveBadge {
        margin-right: 10px;
    }
}

@media (min-width: 833px) and (max-width: 839px) {
    .redriveBadge {
        margin-right: 5px;
    }
}

@media (min-width: 830px) and (max-width: 832px) {
    .redriveBadge {
        margin-right: 2px;
    }
}

@media (min-width: 828px) and (max-width: 829px) {
    .redriveBadge {
        margin-right: 0;
    }
}

@media (max-width: 827px) {
    .redriveBadge {
        margin-bottom: 5px;
    }
}

@media (min-width: 1064px) and (max-width: 1420px) {
    .labelRow {
        width: 130%;
    }
}

@media (min-width: 1420px) and (max-width: 2000px) {
    .labelRow {
        width: 190%;
    }
}

@media (min-width: 2000px) {
    .labelRow {
        width: 220%;
    }
}

@media (min-width: 800px) and (max-width: 1064px){
    .labelRow {
        width: 545px;
    }
}

@media (max-width: 1064px) {
    .workflowName {
        margin-bottom: 2.5%;
    }
}

@media (min-width: 1064px) {
    .workflowName {
        min-width: 250px;
    }
}